import React from 'react';

export default ({ className }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3646 6.10453C16.6743 6.10453 16.1146 6.66382 16.1146 7.35453C16.1146 8.04489 16.6743 8.60453 17.3646 8.60453C18.0554 8.60453 18.6146 8.04489 18.6146 7.35453C18.6146 6.66382 18.0554 6.10453 17.3646 6.10453ZM11.9482 9.54203C10.1104 9.54203 8.61464 11.0374 8.61464 12.8752C8.61464 14.7131 10.1104 16.2085 11.9482 16.2085C13.7857 16.2085 15.2814 14.7131 15.2814 12.8752C15.2814 11.0374 13.7857 9.54203 11.9482 9.54203ZM11.9482 17.8752C9.19071 17.8752 6.94821 15.6324 6.94821 12.8752C6.94821 10.1181 9.19071 7.87524 11.9482 7.87524C14.7054 7.87524 16.9482 10.1181 16.9482 12.8752C16.9482 15.6324 14.7054 17.8752 11.9482 17.8752ZM6.16679 4.54203C4.78821 4.54203 3.66679 5.66346 3.66679 7.04203V18.7085C3.66679 20.087 4.78821 21.2085 6.16679 21.2085H17.8332C19.2121 21.2085 20.3336 20.087 20.3336 18.7085V7.04203C20.3336 5.66346 19.2121 4.54203 17.8332 4.54203H6.16679ZM17.8332 22.8752H6.16679C3.86964 22.8752 2 21.006 2 18.7085V7.04203C2 4.74453 3.86964 2.87524 6.16679 2.87524H17.8332C20.1307 2.87524 22 4.74453 22 7.04203V18.7085C22 21.006 20.1307 22.8752 17.8332 22.8752Z" fill="white"/>
    <mask id="mask0_329_1293" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21">
    <path fillRule="evenodd" clipRule="evenodd" d="M17.3646 6.10453C16.6743 6.10453 16.1146 6.66382 16.1146 7.35453C16.1146 8.04489 16.6743 8.60453 17.3646 8.60453C18.0554 8.60453 18.6146 8.04489 18.6146 7.35453C18.6146 6.66382 18.0554 6.10453 17.3646 6.10453ZM11.9482 9.54203C10.1104 9.54203 8.61464 11.0374 8.61464 12.8752C8.61464 14.7131 10.1104 16.2085 11.9482 16.2085C13.7857 16.2085 15.2814 14.7131 15.2814 12.8752C15.2814 11.0374 13.7857 9.54203 11.9482 9.54203ZM11.9482 17.8752C9.19071 17.8752 6.94821 15.6324 6.94821 12.8752C6.94821 10.1181 9.19071 7.87524 11.9482 7.87524C14.7054 7.87524 16.9482 10.1181 16.9482 12.8752C16.9482 15.6324 14.7054 17.8752 11.9482 17.8752ZM6.16679 4.54203C4.78821 4.54203 3.66679 5.66346 3.66679 7.04203V18.7085C3.66679 20.087 4.78821 21.2085 6.16679 21.2085H17.8332C19.2121 21.2085 20.3336 20.087 20.3336 18.7085V7.04203C20.3336 5.66346 19.2121 4.54203 17.8332 4.54203H6.16679ZM17.8332 22.8752H6.16679C3.86964 22.8752 2 21.006 2 18.7085V7.04203C2 4.74453 3.86964 2.87524 6.16679 2.87524H17.8332C20.1307 2.87524 22 4.74453 22 7.04203V18.7085C22 21.006 20.1307 22.8752 17.8332 22.8752Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_329_1293)">
    <rect y="0.875244" width="24" height="24" fill="white"/>
    </g>
  </svg>
);
