import React from 'react';

export default ({ className }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8967 2.87524H3.1037C2.49481 2.87524 2 3.37022 2 3.97857V21.7719C2 22.3821 2.49481 22.8752 3.1037 22.8752H12.6844V15.1312H10.0752V12.112H12.6822V9.88425C12.6822 7.29894 14.2604 5.89255 16.5648 5.89255C17.6685 5.89255 18.7585 5.9748 19.0344 6.01185V8.80907H17.2944C16.0426 8.80907 15.7885 9.4052 15.7885 10.2785V12.1109H18.7878L18.3996 15.1301H15.7885V22.8752H20.8959C21.5059 22.8752 22 22.3814 22 21.7719V3.97857C22.0007 3.37022 21.5067 2.87524 20.8967 2.87524Z" fill="white"/>
    <mask id="mask0_329_1290" maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="21">
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8967 2.87524H3.1037C2.49481 2.87524 2 3.37022 2 3.97857V21.7719C2 22.3821 2.49481 22.8752 3.1037 22.8752H12.6844V15.1312H10.0752V12.112H12.6822V9.88425C12.6822 7.29894 14.2604 5.89255 16.5648 5.89255C17.6685 5.89255 18.7585 5.9748 19.0344 6.01185V8.80907H17.2944C16.0426 8.80907 15.7885 9.4052 15.7885 10.2785V12.1109H18.7878L18.3996 15.1301H15.7885V22.8752H20.8959C21.5059 22.8752 22 22.3814 22 21.7719V3.97857C22.0007 3.37022 21.5067 2.87524 20.8967 2.87524Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_329_1290)">
    <rect y="0.875244" width="24" height="24" fill="white"/>
    </g>
  </svg>
);
