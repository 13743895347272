import { useCursorHandlers } from '../../../contexts/CursorContext/Cursor';
import { useTheme } from '@mui/material/styles';

export const useEditorHandlers = (name: string) => {
  const theme = useTheme();

  return useCursorHandlers({
    name,
    styles: [
      {
        name: 'theme_component_footer_background',
        label: 'Background color',
        type: 'color',
        default: '#003367',
      },
      {
        name: 'theme_component_footer_border_bottom',
        label: 'Border color',
        type: 'color',
        default: '#1e649e',
      },
      {
        name: 'theme_component_footer_hint_color',
        label: 'Text color',
        type: 'color',
        default: '#fff',
      },
      {
        name: 'theme_component_footer_social_title_color',
        label: 'Text color',
        type: 'color',
        default: '#fff',
      },
      {
        name: 'theme_component_footer_link_container_color',
        label: 'Text color',
        type: 'color',
        default: '#fff',
      },
      {
        name: 'theme_component_footer_ccpa_link_color',
        label: 'Text color',
        type: 'color',
        default: '#fff',
      },
      {
        name: 'theme_component_footer_one_trust_link_color',
        label: 'Text color',
        type: 'color',
        default: '#fff',
      },
      {
        name: 'theme_component_footer_bottom_container_background_color',
        label: 'Text color',
        type: 'color',
        default: '#000',
      },
    ],
    configuration: [
      {
        name: 'component_footer_bar_azerion_footer',
        label: 'Azerion branded footer visible',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_advertisement_show',
        label: 'Show Sticky Advertisement',
        type: 'checkbox',
        default: false,
      },
      {
        name: 'component_footer_bar_c_logo_height',
        label: 'Logo Height',
        type: 'text',
        helperText: 'Please write write with px. Example "60px"',
      },
      {
        name: 'component_footer_bar_c_logo_width',
        label: 'Logo Width',
        type: 'text',
        helperText: 'Please write write with px. Example "40px"',
      },
      {
        name: 'component_footer_bar_c_cookie_settings_text',
        label: 'Logo Cookie Settings Text',
        type: 'text',
        helperText: '"Your data is safe with us"',
      },
      {
        name: 'component_footer_bar_privacy_url',
        label: 'Footer Bar Privacy URL',
        type: 'text',
        helperText: 'External Link',
      },
      {
        name: 'component_footer_bar_terms_url',
        label: 'Footer Bar Terms of use URL',
        type: 'text',
        helperText: 'External Link',
      },
      {
        name: 'component_footer_bar_cookies_url',
        label: 'Footer Bar Cookies URL',
        type: 'text',
        helperText: 'External Link',
      },
      {
        name: 'component_footer_bar_colophon_text',
        label: 'Footer Bar Colophon Text',
        type: 'text',
        helperText: '© Copyright Text ',
      },
      {
        name: 'component_footer_bar_app_store_url',
        label: 'Footer Bar Apple App Store URL',
        type: 'text',
        helperText: 'App store application download link',
      },
      {
        name: 'component_footer_bar_google_play_url',
        label: 'Footer Bar Google Store URL',
        type: 'text',
        helperText: 'Google store application download link',
      },
      {
        name: 'component_footer_bar_instagram_url',
        label: 'Footer Bar Social Media Instragram  URL',
        type: 'text',
      },
      {
        name: 'component_footer_bar_facebook_url',
        label: 'Footer Bar Social Media Facebook URL',
        type: 'text',
      },
      {
        name: 'component_footer_bar_twitter_url',
        label: 'Footer Bar Social Media Twitter URL',
        type: 'text',
      },
      {
        name: 'component_footer_bar_c_links',
        label: 'Navigation Bar Custom Links',
        type: 'multipleAdd',
        default: [{ href: '', title: '' }],
        recipe: ['href', 'title'],
        helperText: 'Footer Customizable Links',
      },
      {
        name: 'component_footer_bar_c_extra_link',
        label: 'Navigation Bar Extra Link',
        type: 'multipleAdd',
        default: [{ href: '', title: '' }],
        recipe: ['href', 'title'],
        helperText: 'Footer Extra Customizable Links',
      },
    ],
  });
};
