import React from 'react';

export default ({ className }) => (
  <svg width="24" height="25" viewBox="0 0 24 25" className={className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 15.8752V9.87524L15.196 12.8752L10 15.8752ZM21.582 8.06158C21.3523 7.20091 20.6743 6.52324 19.8137 6.29324C18.2543 5.87524 12 5.87524 12 5.87524C12 5.87524 5.74567 5.87524 4.18633 6.29324C3.32567 6.52324 2.64767 7.20091 2.418 8.06158C2 9.62091 2 12.8752 2 12.8752C2 12.8752 2 16.1296 2.418 17.6889C2.64767 18.5496 3.32567 19.2272 4.18633 19.4572C5.74567 19.8752 12 19.8752 12 19.8752C12 19.8752 18.2543 19.8752 19.8137 19.4572C20.6743 19.2272 21.3523 18.5496 21.582 17.6889C22 16.1296 22 12.8752 22 12.8752C22 12.8752 22 9.62091 21.582 8.06158Z" fill="white"/>
    <mask id="mask0_329_1292" maskUnits="userSpaceOnUse" x="2" y="5" width="20" height="15">
    <path fillRule="evenodd" clipRule="evenodd" d="M10 15.8752V9.87524L15.196 12.8752L10 15.8752ZM21.582 8.06158C21.3523 7.20091 20.6743 6.52324 19.8137 6.29324C18.2543 5.87524 12 5.87524 12 5.87524C12 5.87524 5.74567 5.87524 4.18633 6.29324C3.32567 6.52324 2.64767 7.20091 2.418 8.06158C2 9.62091 2 12.8752 2 12.8752C2 12.8752 2 16.1296 2.418 17.6889C2.64767 18.5496 3.32567 19.2272 4.18633 19.4572C5.74567 19.8752 12 19.8752 12 19.8752C12 19.8752 18.2543 19.8752 19.8137 19.4572C20.6743 19.2272 21.3523 18.5496 21.582 17.6889C22 16.1296 22 12.8752 22 12.8752C22 12.8752 22 9.62091 21.582 8.06158Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_329_1292)">
    <rect y="0.875244" width="24" height="24" fill="white"/>
    </g>
  </svg>
);
